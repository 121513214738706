<template>
    <div class="section">
        <div class="box">
            <!--
            <b-field label="Aktif Forex Markasını Giriniz">
                <b-taginput v-model="activeForexBrand" ellipsis icon="label" placeholder="Marka Ekle"
                    aria-close-label="Grubu Sil" />
            </b-field>
            -->
            <b-field label="Aktif Banka Transferi Grubunu Giriniz">
                <b-taginput v-model="activeBankTransferGroup" ellipsis icon="label" placeholder="Grup Ekle"
                    aria-close-label="Grubu Sil" @blur="changeGroup" @keyup.enter.native="changeGroup" />
            </b-field>
            <div class="is-flex is-flex-wrap-wrap mb-3 transfer-groups">
                <b-field v-for="(item, key) in rates" :key="key" :label="key + '(%)'" class="mr-3">
                    <b-input v-model="rates[key]" @keyup.native="calcTotal" />
                </b-field>
            </div>
            <div class="is-flex">
                <b-field label="Toplam Girilen Oran">
                    <span>%{{ total }}</span>
                </b-field>
                <b-button type="is-info" class="mr-3 is-align-self-flex-end" @click="rateSync">Eşit Dağıt</b-button>
                <b-button type="is-success" class="is-align-self-flex-end" @click="save"
                    :disabled="rateTotal !== 0 && rateTotal !== 100">Güncelle</b-button>
                </div>
        </div>
        <div class="card mt-3">
            <div class="card-header has-background-black">
                <div class="card-header-title has-text-white">Grupların günlük yatırımları ve yüzdesel bilgileri</div>
            </div>
            <div class="card-content">
                <b-field grouped v-for="(item, key, index) in groups" :key="key">
                    <b-field :label="index === 0 ? 'Grup' : ''">
                        <b-input v-model="item.group" disabled />
                    </b-field>
                    <b-field :label="index === 0 ? 'Yansıtılan Oran' : ''">
                        <b-input v-model="item.realRate" disabled />
                    </b-field>
                    <b-field :label="index === 0 ? 'Oran' : ''">
                        <b-input v-model="item.rate" disabled />
                    </b-field>
                    <b-field :label="index === 0 ? 'Fark' : ''" :type="item.diff <= 0 ? 'is-danger' : 'is-success'">
                        <b-input v-model="item.diff" disabled class="is-danger" />
                    </b-field>
                    <b-field :label="index === 0 ? 'Alınan Yatırım' : ''">
                        <div style="font-style: italic;font-weight:bold">₺{{ numberFormat(item.total) }}</div>
                    </b-field>
                </b-field>
            </div>
        </div>
    </div>
</template>


<script>
import { SettingService, ReportService } from '@/services'
export default {
    name: 'Setting',
    mounted() {
        SettingService.edit(1).then(res => {
            this.activeBankTransferGroup = res.data.activeBankTransferGroup.split(",")
            this.activeForexBrand = res.data.forexBrands ? res.data.forexBrands.split(",") : []
            this.groupRates = res.data.groupRates
            if (!res.data.groupRates) {
                let rates = {}
                this.activeBankTransferGroup.map(item => rates[item] = 0)
                this.rates = {...rates}
            } else {
                this.activeBankTransferGroup.map(item => typeof this.groupRates[item] !== 'undefined' ? this.rates[item] = this.groupRates[item] : this.rates[item] = 0)
            }
             this.calcTotal()
        })


        ReportService.group({ startDate: this.getIstanbulDate(new Date()), endDate: this.getIstanbulDate(new Date(), false) }).then(res => this.groups = res.data)
    },
    data() {
        return {
            activeBankTransferGroup: [],
            rates: {},
            grupRates: {},
            groups: [],
            activeForexBrand: [],
            total: 0
        }
    },
    computed: {
        rateTotal() {
            if (!this.rates) {
                return 0
            }
            let total = 0
            Object.values(this.rates).map(item => {
                total += parseFloat(item)
            })
            return parseFloat(total)
        },
    },
    methods: {
        calcTotal(){
            let total = 0
            Object.values(this.rates).map((item, key) => {
                total += parseFloat(item) || 0
            })
            this.total = total
        },
        rateSync() {
            const rate = (100 / Object.keys(this.rates).length)
            let newRates = {}
            Object.values(this.rates).map((item, key) => {
                newRates[Object.keys(this.rates)[key]] = parseFloat(rate).toFixed(2)
            })
            this.rates = { ...newRates }
            this.calcTotal()
        },
        changeGroup() {
            this.activeBankTransferGroup = [...this.activeBankTransferGroup]
            this.activeBankTransferGroup.map(item => typeof this.groupRates[item] !== 'undefined' ? this.rates[item] = this.groupRates[item] : this.rates[item] = 0)
        },
        save() {
            SettingService.update({
                id: 1,
                activeBankTransferGroup: this.activeBankTransferGroup.join(','),
                groupRates: this.rates,
                forexBrands: this.activeForexBrand.join(',')
            })
                .then(() => {
                    this.$buefy.toast.open({
                        message: 'Güncellendi',
                        type: 'is-success'
                    })
                })
                .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
        }
    }
}
</script>

<style>
.transfer-groups>.field {
    margin: 0
}
</style>