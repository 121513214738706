<template>
	<div id="maldoWithdrawPanel">
		<section class="section is-main-section p-4">
			<div class="tile is-ancestor is-flex-wrap-wrap">
				<div class="tile is-parent is-3" v-if="withdraw.CONFIRMED">
					<article class="tile is-child">
						<card-component
							:title="`Confirmed`"
							:title-class="`has-background-info has-text-white`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.CONFIRMED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number :value="withdraw.CONFIRMED.count" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
				<div class="tile is-parent is-3" v-if="withdraw.TAKED">
					<article class="tile is-child">
						<card-component :title="`Taked`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.TAKED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number :value="withdraw.TAKED.total" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
				<div class="tile is-parent is-3" v-if="withdraw.DECLINED">
					<article class="tile is-child">
						<card-component
							:title="`Declined`"
							:title-class="`has-background-danger has-text-white`">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.DECLINED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number :value="withdraw.DECLINED.total" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
				<div class="tile is-parent is-3" v-if="withdraw.PAYED">
					<article class="tile is-child">
						<card-component
							:title="`Ödenenler`"
							:title-class="'has-background-success has-text-white'">
							<div
								class="is-widget-label is-align-self-flex-start">
								<h1 class="title is-spaced mb-9">
									<growing-number
										:value="withdraw.PAYED.total"
										:prefix="'₺'" />
								</h1>
							</div>
							<div
								class="is-widget-label is-align-self-flex-start mt-2">
								<div class="subtitle is-7 mb-0">
									adet.
									<growing-number :value="withdraw.PAYED.total" />
								</div>
							</div>
						</card-component>
					</article>
				</div>
			</div>
		</section>
		<router-view />
	</div>
</template>
<script>
	import Tiles from "@/components/Tiles"
	import CardWidget from "@/components/CardWidget"
	import CardComponent from "@/components/CardComponent"
	import ClientsTableSample from "@/components/ClientsTableSample"
	import GrowingNumber from "@/components/GrowingNumber.vue"
	import { MaldoService as Service } from "@/services"
	export default {
		name: "MaldoWithdrawPanel",
		components: {
			CardComponent,
			CardWidget,
			Tiles,
			ClientsTableSample,
			GrowingNumber,
		},
		data() {
			return { withdraw: {TAKED: 0, CONFIRMED: 0, DECLINED: 0, PAYED: 0} }
		},
		mounted() {

		},
		methods: {},
	}
</script>
